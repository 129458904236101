export const menuItems = [
    {
        key: '001',
        name: '메뉴소개',
        subMenus: [
            { name: '오늘의 도시락', to: '/menu/today', code: 'TODAY', key: '002' },
            { name: '실속형 도시락', to: '/menu/nofrills', code: 'NOFRILLS', key: '003' },
            { name: '맞춤형 프리미엄 도시락', to: '/menu/customized-premium', code: 'CUSTOMIZED_PREMIUM', key: '004' },
            { name: '프리미엄 도시락', to: '/menu/premium', code: 'PREMIUM', key: '005' },
            { name: '웨딩&피크닉 도시락', to: '/menu/wedding', code: 'WEDDING', key: '006' },
            { name: '간편식 도시락', to: '/menu/convenience', code: 'CONVENIENCE', key: '007' },
            { name: '샌드위치&샐러드', to: '/menu/sandwich', code: 'SANDWICH', key: '008' },
            { name: '음료', to: '/menu/drink', code: 'DRINK', key: '009' },
        ],
    },
    {
        key: '002',
        name: '이용방법',
        subMenus: [
            { name: '정기배송', to: '/used/regular-delivery', code: 'REGULAR', key: '001' },
            { name: '맞춤배송', to: '/used/fit-delivery' },
            { name: '식단표', to: '/used/foods' },
        ],
    },
    {
        key: '003',
        name: '새소식',
        subMenus: [
            { name: '이벤트', to: '/news/event' },
            { name: '공지사항', to: '/news/notice' },
        ],
    },
    {
        key: '003',
        name: '꼬륵',
        subMenus: [{ name: '브랜드 소개', to: '/intro' }],
    },
    {
        key: '005',
        name: '리뷰',
        subMenus: [
            { name: '뉴스', to: '/review/news' },
            { name: '유튜브', to: '/review/youtube' },
        ],
    },
];

const menuCode = [
    { name: '정기배송', to: '/used/regular-delivery', code: 'REGULAR', key: '001' },
    { name: '오늘의 도시락', to: '/menu/today', code: 'TODAY', key: '002' },
    { name: '실속형 도시락', to: '/menu/nofrills', code: 'NOFRILLS', key: '003' },
    { name: '맞춤형 프리미엄 도시락', to: '/menu/customized-premium', code: 'CUSTOMIZED_PREMIUM', key: '004' },
    { name: '프리미엄 도시락', to: '/menu/premium', code: 'PREMIUM', key: '005' },
    { name: '웨딩&피크닉 도시락', to: '/menu/wedding', code: 'WEDDING', key: '006' },
    { name: '간편식 도시락', to: '/menu/convenience', code: 'CONVENIENCE', key: '007' },
    { name: '샌드위치&샐러드', to: '/menu/sandwich', code: 'SANDWICH', key: '008' },
    { name: '음료', to: '/menu/drink', code: 'DRINK', key: '009' },
];

export const getMenuCode = (key: string) => {
    return menuCode.find((item) => item.key === key);
};

export const getMenuItem = (key: '001' | '002' | '003' | '004' | '005') => {
    const menuItem = menuItems.find((item) => item.key === key);
    if (menuItem) {
        return menuItem.subMenus;
    } else {
        return [];
    }
};
